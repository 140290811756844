@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap);
.centered {
  display: flex;
  align-items: center;
  justify-content: center; }

.flex-row, .flex-row-center {
  display: flex;
  flex-direction: row; }

.flex-row-center {
  align-items: center; }

.flex-column, .flex-column-justify-center, .flex-column-justify-bottom, .flex-column-align-center {
  display: flex;
  flex-direction: column; }

.flex-column-justify-center {
  justify-content: center; }

.flex-column-justify-bottom {
  justify-content: flex-end; }

.flex-column-align-center {
  align-items: center; }

.m-t-5 {
  margin-top: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-v-5 {
  margin-top: 5px;
  margin-bottom: 5px; }

.m-v-10 {
  margin-top: 10px;
  margin-bottom: 10px; }

.m-v-15 {
  margin-top: 15px;
  margin-bottom: 15px; }

.m-v-20 {
  margin-top: 20px;
  margin-bottom: 20px; }

.p-v-5 {
  padding-top: 5px;
  padding-bottom: 5px; }

.p-v-10 {
  padding-top: 10px;
  padding-bottom: 10px; }

.p-v-15 {
  padding-top: 15px;
  padding-bottom: 15px; }

.p-v-20 {
  padding-top: 20px;
  padding-bottom: 20px; }

.font-primary-color {
  color: #F8F7F8; }

.font-primary-highlight {
  color: #72b5cc; }

.font-secondary-color {
  color: #F9FAFB; }

.font-secondary-highlight {
  color: #ffd879; }

.font-weight-100 {
  font-weight: 100 !important; }

.font-weight-200 {
  font-weight: 200 !important; }

.font-weight-300 {
  font-weight: 300 !important; }

.font-weight-400 {
  font-weight: 400 !important; }

.font-weight-500 {
  font-weight: 500 !important; }

.font-weight-600 {
  font-weight: 600 !important; }

.font-weight-700 {
  font-weight: 700 !important; }

.font-weight-800 {
  font-weight: 800 !important; }

.draw-border {
  box-shadow: inset 0 0 0 3px #72b5cc;
  color: #72b5cc;
  -webkit-transition: color 0.25s 0.08333s;
  transition: color 0.25s 0.08333s;
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1.5;
  font: "Open Sans", sans-serif;
  padding: 0.8em 1.8em;
  letter-spacing: 0.05rem; }
  .draw-border::before, .draw-border::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;
    right: 0; }
  .draw-border::before {
    border-bottom-width: 3px;
    border-left-width: 3px; }
  .draw-border::after {
    border-top-width: 3px;
    border-right-width: 3px; }
  .draw-border:hover {
    color: #615f5f; }
    .draw-border:hover::before, .draw-border:hover::after {
      border-color: #615f5f;
      -webkit-transition: border-color 0s, width 0.25s, height 0.25s;
      transition: border-color 0s, width 0.25s, height 0.25s;
      width: 100%;
      height: 100%; }
    .draw-border:hover::before {
      -webkit-transition-delay: 0s, 0s, 0.25s;
              transition-delay: 0s, 0s, 0.25s; }
    .draw-border:hover::after {
      -webkit-transition-delay: 0s, 0.25s, 0s;
              transition-delay: 0s, 0.25s, 0s; }
  .draw-border:focus {
    outline: none; }

.hover-grow, .hover-grow-tiny, .hover-grow-small, .hover-grow-medium, .hover-grow-large {
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out; }

.hover-grow-tiny:hover {
  -webkit-transform: scale(1.01);
          transform: scale(1.01); }

.hover-grow-small:hover {
  -webkit-transform: scale(1.025);
          transform: scale(1.025); }

.hover-grow-medium:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05); }

.hover-grow-medium:hover {
  -webkit-transform: scale(1.075);
          transform: scale(1.075); }

.hover-grow-large:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

.object-fit-contain {
  object-fit: contain; }

body {
  background-color: white;
  color: #F8F7F8;
  overflow-x: hidden; }

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden; }

main {
  flex-grow: 1; }

main, section {
  position: relative; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", sans-serif; }

h1 {
  font-weight: 600;
  color: #202020; }

h2 {
  font-weight: 600;
  color: #202020; }

p, div {
  font-family: "Open Sans", sans-serif;
  color: #615f5f; }

p {
  font-size: 1.1em;
  line-height: 1.6em; }

img {
  max-width: 100%;
  object-fit: cover;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

button {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

ul {
  padding-left: 20px; }

a, a:visited {
  color: inherit; }

.sectionWrapper {
    overflow: hidden;
    -webkit-perspective: 50px;
            perspective: 50px;
}

.waveContainer {
    height: 150px;
    -webkit-filter: drop-shadow(-1px -3px 1px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(-1px -3px 1px rgba(0, 0, 0, 0.1));
}

.waveSVG {
    height: 100%;
    width: 100%;
}

.wavePath {
    stroke: none;
}

.content {
    padding-top: 30px;
    padding-bottom: 30px;
}


.outer-wrap {
  background-color: #2892ca;
  border-radius: 5px;
  padding: 7px;
  padding-top: 0px; }

.buttons-section-container {
  padding-top: 14px;
  padding-bottom: 14px; }

.black-circle-button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #FEF9FF; }

.black-circle-button-spacer-left {
  margin-left: 7px; }

.inner-wrap {
  border-radius: 5px;
  overflow: hidden; }

.logo-showcase-logos {
  height: 290px;
  object-fit: contain; }

.logo-showcase-description {
  width: 90%;
  text-align: center; }

.header {
  background-color: #F8F7F8;
  border-bottom-color: #615f5f;
  border-width: 0px;
  border-bottom-width: 1px;
  border-style: solid; }

.header-image {
  height: 46px; }

.contact-left-item-spacing-helper {
  margin-right: 0px; }
  @media only screen and (min-width: 420px) {
    .contact-left-item-spacing-helper {
      margin-right: 20px; } }

.header-contact-button p {
  color: inherit; }

.header-contact-button:hover {
  color: #72b5cc; }

.hero-image-logo-spacer {
  margin-top: -15vh; }

