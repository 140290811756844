.sectionWrapper {
    overflow: hidden;
    perspective: 50px;
}

.waveContainer {
    height: 150px;
    filter: drop-shadow(-1px -3px 1px rgba(0, 0, 0, 0.1));
}

.waveSVG {
    height: 100%;
    width: 100%;
}

.wavePath {
    stroke: none;
}

.content {
    padding-top: 30px;
    padding-bottom: 30px;
}

