@import "../../styles/variables.scss";

$padding-val: 7px;

.outer-wrap {
  background-color: #2892ca;
  border-radius: 5px;
  padding: $padding-val;
  padding-top: 0px;
}

.buttons-section-container {
  padding-top: $padding-val * 2;
  padding-bottom: $padding-val * 2;

}

.black-circle-button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $color4;
}

.black-circle-button-spacer-left {
  margin-left: 7px;
}

.inner-wrap {
  border-radius: 5px;
  overflow: hidden;
}
